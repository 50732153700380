import React from "react";
import { Box } from "@mui/material";
import nyssc_sponsor_screen from "assets/mp4/2024_nyssc_sponsor_screen.mp4";

const AwardsWelcome = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "black",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <video
        style={{
          maxWidth: "100%", // Ensures the video does not exceed the viewport width
          maxHeight: "100vh", // Ensures the video does not exceed the viewport height
          width: "auto", // Adjusts width to maintain aspect ratio
          height: "auto", // Adjusts height to maintain aspect ratio
        }}
        src={nyssc_sponsor_screen}
        autoPlay
        loop
        muted
      />
    </Box>
  );
};

export default AwardsWelcome;

import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import AwardBackground from "./AwardBackground";

const AwardSlide = ({ award, awardRecipients }) => {
  console.log("awardslide/awardRecipients", awardRecipients);
  return (
    <AwardBackground>
      <Box
        sx={{
          position: "absolute",
          top: "5%",
          height: "70%",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          fontSize="3rem"
          fontWeight={600}
          color={"white"}
          mb={4}
        >
          {award.Name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            boxSizing: "border-box",
            gap: 6,
          }}
        >
          {awardRecipients.map(({ projectData, studentsData }) => (
            <Box
              key={projectData.RECORD_ID}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                px: 5,
              }}
            >
              <Typography
                variant="h5"
                fontSize={"3rem"}
                fontWeight={300}
                fontStyle={"italic"}
                textAlign={"center"}
              >
                {projectData.Name}
              </Typography>
              <Typography variant="body1" fontSize={"4rem"} fontWeight={500}>
                {studentsData.map((student) => student.Name).join(", ")}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </AwardBackground>
  );
};

AwardSlide.propTypes = {
  award: PropTypes.object.isRequired,
  awardRecipients: PropTypes.array.isRequired,
};

export default AwardSlide;

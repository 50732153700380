// src/routes/routesConfig.js
import NotFoundPage from "pages/NotFoundPage";
import SponsorSlide from "pages/SponsorSlide";
import AwardsPresentation from "pages/AwardsPresentation";
import AwardsPrint from "pages/AwardsPrint";
import AwardsWeb from "pages/AwardsWeb";
// import AboutPage from './Nope/Page';
// import NotFoundPage from './components/NotFoundPage';

const routesConfig = [
  { path: "/", element: AwardsWeb, exact: true },
  { path: "/sponsor-slide", element: SponsorSlide, exact: true },
  { path: "/awards", element: AwardsPrint, exact: true },
  { path: "/awards-presentation", element: AwardsPresentation, exact: true },
  { path: "/awards/print", element: AwardsPrint, exact: true },
  { path: "*", element: NotFoundPage },
];

export default routesConfig;

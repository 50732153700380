import React, { useState, useEffect } from "react";
import nyssc_sponsor_screen from "assets/mp4/2024_nyssc_sponsor_screen.mp4";

import Typography from "@mui/material/Typography";

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000); // Update the time every second

    return () => clearInterval(timer); // Clear interval on component unmount
  }, []);

  const timeOptions = {
    hour: "numeric",
    minute: "2-digit",
  };

  return (
    <div
      style={{
        color: "white",
        fontSize: "24px",
        position: "absolute",
        top: "18%",
        left: "10%",
        zIndex: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "29.5%",
        boxSizing: "border-box",
        textShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
      }}
    >
      <Typography variant="h4" fontSize={"5vh"} fontWeight={500}>
        Current Time
      </Typography>
      <Typography variant="h3" fontSize={"8.5vh"} fontWeight={600}>
        {time.toLocaleTimeString([], timeOptions)}
      </Typography>
    </div>
  );
};

const SponsorSlide = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
      }}
    >
      <Clock />
      <video
        style={{
          maxWidth: "100%", // Ensures the video does not exceed the viewport width
          maxHeight: "100vh", // Ensures the video does not exceed the viewport height
          width: "auto", // Adjusts width to maintain aspect ratio
          height: "auto", // Adjusts height to maintain aspect ratio
        }}
        src={nyssc_sponsor_screen}
        autoPlay
        loop
        muted
      />
    </div>
  );
};

export default SponsorSlide;

import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import AwardBackground from "./AwardBackground";

const AwardTitleSlide = ({ title }) => {
  return (
    <AwardBackground>
      <Typography variant="h3" fontSize={"5rem"} fontWeight={600} color="white">
        {title}
      </Typography>
    </AwardBackground>
  );
};

export default AwardTitleSlide;

AwardTitleSlide.propTypes = {
  title: PropTypes.string.isRequired,
};

import React from "react";
import AwardBackground from "./AwardBackground";
import { Typography, Box } from "@mui/material";

const AwardsEndSlide = () => {
  return (
    <AwardBackground>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h3" align="center" color="white" gutterBottom>
          Thank you for attending the 2024 New York State Science Congress!
        </Typography>
        <Typography variant="h3" align="center" color="white" gutterBottom>
          Project ranking can be found at <br />
          nyssc.most.org/awards
        </Typography>
      </Box>
    </AwardBackground>
  );
};

export default AwardsEndSlide;

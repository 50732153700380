// Import airtable from 'airtable';
import Airtable from "airtable";
Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
});

const base = Airtable.base(process.env.REACT_APP_AIRTABLE_BASE_ID);
export default base;

export const projects_table = base("projects");
export const students_table = base("students");
export const fair_table = base("fair_year");
export const awards_table = base("awards");

export async function fetchTableData(table, options = {}) {
  try {
    const queryOptions = {
      ...options,
    };
    const response = await table.select(queryOptions).all();
    if (response) {
      return response.map((record) => {
        return {
          PKID: record.id,
          RECORD_ID: record.id,
          ...record.fields,
        };
      });
    }
  } catch (error) {
    console.error("fetch table data error", error);
    return [];
  }
}

export async function getCurrentFairEvent() {
  try {
    const currentYear = new Date().getFullYear();
    const response = await fair_table
      .select({
        filterByFormula: `Year = ${currentYear}`,
      })
      .firstPage();

    if (response && response.length > 0) {
      const fairEvent = response[0];
      return {
        PKID: fairEvent.id,
        RECORD_ID: fairEvent.id,
        ...fairEvent.fields,
      };
    } else {
      console.error("No fair event found for the current year");
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getAwardsForFairEvent(fairEventID) {
  try {
    const filter = `FIND("${fairEventID}", fair_year)`;
    const response = await awards_table
      .select({
        filterByFormula: filter,
        sort: [{ field: "presentation_order", direction: "asc" }],
      })
      .all();

    return response.map((award) => {
      return {
        PKID: award.id,
        RECORD_ID: award.id,
        ...award.fields,
      };
    });
  } catch (error) {
    console.error("Error fetching awards for the fair event", error);
    throw error;
  }
}

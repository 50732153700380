import React from "react";
import { useData } from "context/DataContext";
import { Typography, Box } from "@mui/material";

const AwardCard = ({ award, projectDetails }) => {
  return (
    <div
      className={"awardCard"}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h5" fontWeight={600} gutterBottom>
        {award.Name}
      </Typography>
      {projectDetails.map(({ projectData, studentsData }) => (
        <div
          key={projectData.RECORD_ID}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="h6"
            fontsize={".8rem"}
            fontStyle={"italic"}
            textAlign={"center"}
          >
            {projectData.project_title}
          </Typography>
          <Typography variant="body1" fontSize={"1.25rem"} fontWeight={600}>
            {studentsData.map((student) => student.Name).join(", ")}
          </Typography>
        </div>
      ))}
    </div>
  );
};

const SectionMarker = ({ title, subtitle }) => {
  return (
    <Box
      className={"sectionMarker"}
      sx={{
        my: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4" fontWeight={600}>
        {title}
      </Typography>
      {subtitle && <Typography variant="h4">{subtitle}</Typography>}
    </Box>
  );
};

const DivisionAwards = ({ awards, divisionTitle }) => {
  const { projects, students } = useData();

  const getProjectDetails = (award) => {
    return award.fk_project.map((project) => {
      const projectData = projects.find((p) => p.RECORD_ID === project);
      const studentsData = students.filter((s) =>
        projectData.students.includes(s.RECORD_ID)
      );
      return { projectData, studentsData };
    });
  };

  return (
    <Box
      className={"divisionAwards"}
      sx={{
        my: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SectionMarker title={divisionTitle} />
      {awards.map((award) => (
        <AwardCard award={award} projectDetails={getProjectDetails(award)} />
      ))}
    </Box>
  );
};

const AwardsWeb = () => {
  const { awards, fairEvent } = useData();
  console.log("awardsprint/awards", awards);
  const jrDivAwards = awards.filter(
    (award) => award.award_division === "Junior Division (7-9)"
  );
  const srDivAwards = awards.filter(
    (award) => award.award_division === "Senior Division (10-12)"
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <SectionMarker title={fairEvent.nyssc_title} />
      <DivisionAwards
        awards={jrDivAwards}
        divisionTitle="Junior Division Awards"
      />
      <Box
        sx={{
          width: "80%",
        }}
      >
        <iframe
          title="Junior Division Results"
          className="airtable-embed"
          src="https://airtable.com/embed/appkh4kWhApGhsnwt/shrkWZ5ZOiIZtB4VQ"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="533"
          //   style={"background: transparent; border: 1px solid #ccc;"}
        ></iframe>
      </Box>
      <DivisionAwards
        awards={srDivAwards}
        divisionTitle="Senior Division Awards"
      />
      <Box
        sx={{
          width: "80%",
        }}
      >
        <iframe
          title="Senior Division Results"
          class="airtable-embed"
          src="https://airtable.com/embed/appkh4kWhApGhsnwt/shrYcWOsjeZgR0SpQ?viewControls=on"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="533"
          //   style="background: transparent; border: 1px solid #ccc;"
        ></iframe>
      </Box>
    </div>
  );
};

export default AwardsWeb;

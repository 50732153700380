import { useData } from "context/DataContext";
import React, { useState, useEffect } from "react";
import AwardsWelcome from "./awards/AwardsWelcome";
import AwardTitleSlide from "./awards/AwardTitleSlide";
import AwardSlide from "./awards/AwardSlide";
import AwardsEndSlide from "./awards/AwardsEndSlide";

const AwardsPresentation = () => {
  const { awards, projects, students } = useData();

  const totalAwards = awards.length;

  const [currentIndex, setCurrentIndex] = useState(0);

  const awardDivisions = ["Junior Division (7-9)", "Senior Division (10-12)"];

  const slides = [<AwardsWelcome />];
  awardDivisions.forEach((division) => {
    slides.push(
      // Push title slides for each division
      <AwardTitleSlide key={`title-${division}`} title={division} />
    );
    awards
      .filter((award) => award.award_division === division)
      .forEach((award) => {
        const awardProjects = award.fk_project.map((project) => {
          const projectData = projects.find((p) => p.RECORD_ID === project);
          const studentsData = students.filter((s) =>
            projectData.students.includes(s.RECORD_ID)
          );
          return { projectData, studentsData };
        });
        slides.push(
          <AwardSlide
            key={award.RECORD_ID}
            award={award}
            awardRecipients={awardProjects}
          />
        );
      });
  });
  slides.push(<AwardsEndSlide />);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        setCurrentIndex((prevIndex) =>
          Math.min(totalAwards + 3, prevIndex + 1)
        );
      } else if (event.key === "ArrowLeft") {
        setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, totalAwards]);
  console.log("awards", awards);
  return <div>{slides[currentIndex]}</div>;
};

export default AwardsPresentation;

import React from "react";
import PropTypes from "prop-types";

// Import assets
import backgroundImage from "assets/img/awards-background.png";

const AwardBackground = ({ children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

AwardBackground.propTypes = {
  children: PropTypes.node,
};

export default AwardBackground;

import React from "react";
import { useData } from "context/DataContext";
import { Typography, Box } from "@mui/material";

import styles from "./AwardsPrint.module.css";

const AwardCard = ({ award, projectDetails }) => {
  return (
    <div
      className={styles.awardCard}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid black",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h5" fontWeight={600} gutterBottom>
        {award.Name}
      </Typography>
      {projectDetails.map(({ projectData, studentsData }) => (
        <div
          key={projectData.RECORD_ID}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="h6"
            fontsize={".8rem"}
            fontStyle={"italic"}
            textAlign={"center"}
          >
            {projectData.project_title}
          </Typography>
          <Typography variant="body1" fontSize={"1.25rem"} fontWeight={600}>
            {studentsData.map((student) => student.Name).join(", ")}
          </Typography>
        </div>
      ))}
    </div>
  );
};

const SectionMarker = ({ title, subtitle }) => {
  return (
    <Box
      className={styles.sectionMarker}
      sx={{
        my: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4" fontWeight={600}>
        {title}
      </Typography>
      {subtitle && <Typography variant="h4">{subtitle}</Typography>}
    </Box>
  );
};

const DivisionAwards = ({ awards, divisionTitle }) => {
  const { projects, students } = useData();

  const getProjectDetails = (award) => {
    return award.fk_project.map((project) => {
      const projectData = projects.find((p) => p.RECORD_ID === project);
      const studentsData = students.filter((s) =>
        projectData.students.includes(s.RECORD_ID)
      );
      return { projectData, studentsData };
    });
  };

  return (
    <Box
      className={styles.divisionAwards}
      sx={{
        my: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SectionMarker title={divisionTitle} />
      {awards.map((award) => (
        <AwardCard award={award} projectDetails={getProjectDetails(award)} />
      ))}
    </Box>
  );
};

const AwardsPrint = () => {
  const { awards, fairEvent } = useData();
  console.log("awardsprint/awards", awards);
  const jrDivAwards = awards.filter(
    (award) => award.award_division === "Junior Division (7-9)"
  );
  const srDivAwards = awards.filter(
    (award) => award.award_division === "Senior Division (10-12)"
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <SectionMarker
        title={fairEvent.nyssc_title}
        subtitle="Awards Scorecard"
      />
      <Typography
        variant="body1"
        fontWeight={600}
        fontSize={"1.5rem"}
        gutterBottom
      >
        Reamining team results will be posted by the end of today.
      </Typography>
      <DivisionAwards
        awards={jrDivAwards}
        divisionTitle="Junior Division Awards"
      />
      <DivisionAwards
        awards={srDivAwards}
        divisionTitle="Senior Division Awards"
      />
    </div>
  );
};

export default AwardsPrint;

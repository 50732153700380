import React, { createContext, useState, useEffect, useContext } from "react";
import {
  projects_table,
  students_table,
  getCurrentFairEvent,
  fetchTableData,
  getAwardsForFairEvent,
} from "config/airtable";

export const DataContext = createContext();

// Custom hook to use the data from the DataContext
export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [students, setStudents] = useState([]);
  // const [scoring, setScoring] = useState([]);
  const [awards, setAwards] = useState([]);
  // const [juniorDivisionProjects, setJuniorDivisionProjects] = useState([]);
  // const [seniorDivisionProjects, setSeniorDivisionProjects] = useState([]);
  const [fairEvent, setFairEvent] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentFairEvent = await getCurrentFairEvent();
        if (currentFairEvent) {
          setFairEvent(currentFairEvent);
        }

        const [projectRecords, studentRecords] = await Promise.all([
          fetchTableData(projects_table),
          fetchTableData(students_table),
        ]);

        setProjects(projectRecords);
        setStudents(studentRecords);

        if (currentFairEvent) {
          const awardsRecords = await getAwardsForFairEvent(
            currentFairEvent.nyssc_title
          );
          setAwards(awardsRecords);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setErrors((prevErrors) => [...prevErrors, error]);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <DataContext.Provider
      value={{
        projects,
        students,
        // scoring,
        // juniorDivisionProjects,
        // seniorDivisionProjects,
        fairEvent,
        awards,
        errors,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
